import React, { useState } from 'react';
import styled from 'styled-components';

import { Gallery } from 'pages/Gallery';
import { Login } from 'pages/Login';

export const App = () => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	return (
		<Content>
			{isLoggedIn ? <Gallery /> : <Login setIsLoggedIn={setIsLoggedIn} />}
		</Content>
	);
};

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
`;
