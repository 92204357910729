import styled from 'styled-components';

type Props = {
	src: string;
	onClick: () => any;
};

export const Thumbnail = (props: Props) => {
	return <StyledThumbnail {...props} loading="lazy" />;
};

const StyledThumbnail = styled.img`
	width: 150px;
	height: 150px;
	object-fit: cover;
	padding: 5px;
	border: 1px solid #666666;
	border-radius: 4px;
	box-sizing: border-box;
	opacity: 0.8;
	transition: background-size 1s, opacity 0.5s;
	cursor: pointer;
	&:hover {
		opacity: 1;
		background-size: 250px;
	}
`;
