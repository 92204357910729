import React, { useState } from 'react';
import styled from 'styled-components';

import config from 'config.json';

type Props = {
	setIsLoggedIn: (isLoggedIn: boolean) => void;
};

const DEFAULT_MSG = 'Enter a passport to access the secret lair.';

export const Login = (props: Props) => {
	const [pwd, setPwd] = useState<string>('');
	const [message, setMessage] = useState<string>(DEFAULT_MSG);
	const { setIsLoggedIn } = props;

	const handlePwd = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPwd = event.target.value;
		setPwd(newPwd);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		setMessage(DEFAULT_MSG);
		const pressedKey = event.key;
		if (pressedKey === 'Enter') checkPwd();
	};

	const checkPwd = () => {
		if (pwd !== config.PWD) setMessage('Incorrect password');
		else setIsLoggedIn(true);
	};

	return (
		<>
			<p>{message}</p>
			<Row>
				<input
					type="password"
					value={pwd}
					onChange={handlePwd}
					onKeyDown={handleKeyDown}
				/>
				<button onClick={checkPwd}>ENTER</button>
			</Row>
		</>
	);
};

const Row = styled.div`
	display: flex;
	gap: 12px;
`;
