import { useMemo } from 'react';

const importAll = (r: any) => r.keys().map(r);

export const useLoadImg = () => {
	const thumbnailList: string[] = useMemo(() => {
		const rawThumbnailList: string[] = importAll(
			require.context('../__thumbnails/', false, /\.(png|jpe?g|svg)$/),
		);
		return [...new Set(rawThumbnailList)];
	}, []);

	const fullImgList: string[] = useMemo(() => {
		const rawImgList: string[] = importAll(
			require.context('../__img/', false, /\.(png|jpe?g|svg)$/),
		);
		return [...new Set(rawImgList)];
	}, []);

	const getFullImg = (imgName?: string) => {
		if (!imgName) return;
		const fullImgNameRegex = new RegExp(
			/(?<=\/static\/media\/thumb-)[^\.]*(?=\.)/i,
		);
		const fullImgName = fullImgNameRegex.exec(imgName)?.[0];
		if (!fullImgName) return;
		const fullImg = fullImgList.find(img => img.includes(fullImgName));
		return fullImg;
	};

	return { thumbnailList, getFullImg };
};
