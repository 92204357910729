import React, { useState } from 'react';
import styled from 'styled-components';
import { useLoadImg } from 'hooks';

import { ModalPhoto, Thumbnail } from 'containers';

export const Gallery = () => {
	const [selectedImg, setSelectedImg] = useState<string | undefined>();
	const [showImgModal, setShowImgModal] = useState<boolean>(false);

	const { thumbnailList, getFullImg } = useLoadImg();

	const handleThumbnailClick = (imgSrc: string) => {
		setShowImgModal(!showImgModal);
		setSelectedImg(imgSrc);
	};

	return (
		<Content>
			<StyledHeader>
				<h1>Belgrade 2022</h1>
			</StyledHeader>
			<StyledGallery>
				{thumbnailList.map((imgSrc: string) => {
					return (
						<Thumbnail
							key={imgSrc}
							src={imgSrc}
							onClick={() => handleThumbnailClick(imgSrc)}
						/>
					);
				})}
			</StyledGallery>
			<ModalPhoto
				selectedImg={getFullImg(selectedImg)}
				showModal={showImgModal}
				setShowModal={setShowImgModal}
			/>
		</Content>
	);
};

const Content = styled.div`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
`;

const StyledHeader = styled.header`
	position: sticky;
	top: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000000bb;
	color: white;
	z-index: 2;

	h1 {
		margin: 24px;
	}
`;

const StyledGallery = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 12px;
	box-sizing: border-box;
	padding: 24px;
`;
