import React, { useState } from 'react';
import styled from 'styled-components';

type Props = {
	showModal: boolean;
	setShowModal: (showModal: boolean) => void;
	selectedImg?: string;
};

export const ModalPhoto = (props: Props) => {
	const { showModal, setShowModal, selectedImg } = props;
	const [degrees, setDegrees] = useState<number>(0);

	const handleRotatePhoto = (event: React.MouseEvent<HTMLImageElement>) => {
		event.stopPropagation();
		setDegrees(degrees + 90);
	};

	const handleClick = () => {
		setShowModal(false);
	};

	return (
		<StyledModal showModal={showModal} onClick={handleClick}>
			<StyledModalPhoto
				alt="Photograph"
				src={selectedImg}
				degrees={degrees}
				onClick={handleRotatePhoto}
				loading="lazy"
			/>
			<StyledPhotoDescription>
				Rotate photos by clicking on them.
			</StyledPhotoDescription>
		</StyledModal>
	);
};

const StyledModal = styled.div<{ showModal: boolean }>`
	position: absolute;
	display: ${({ showModal }) => (showModal ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.7);
	text-align: center;
	z-index: 10;
`;

const StyledModalPhoto = styled.img<{ degrees: number }>`
	border: 3px solid #fff;
	transform: rotate(${({ degrees }) => degrees ?? 0}deg);
	max-height: 90%;
	max-width: 90%;
`;

const StyledPhotoDescription = styled.p`
	display: block;
	margin: 10px auto;
	vertical-align: middle;
	font-family: 'Open Sans', sans-serif;
	color: #d9cc93;
`;
